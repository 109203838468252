.container {
  bottom: 1vh;
  position: fixed;
  margin: 1em;
  right: 1vw;
}

.item {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: 50%;
  border-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  margin: 20px auto 0;
  position: relative;
  -webkit-transition: transform .1s ease-out, height 100ms ease, opacity 100ms ease;
          transition: transform .1s ease-out, height 100ms ease, opacity 100ms ease;  
  text-decoration: none;
}

.button i {
  font-size: x-large;
}

.link {
  @apply p-2;
  pointer-events: none;
}

.item:active, 
.item:focus, 
.item:hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  transition: box-shadow .2s ease;
  outline: none;
}

.item:not(:last-child) {
  margin: 0px auto 0;
  opacity: 0;
  -webkit-transform: translateY(40px);
  -ms-transform: translateY(40px);
  transform: translateY(40px);
}

.containerOpen 
.item:not(:last-child) {
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin: 15px auto 0;
}

.containerOpen .link {
  pointer-events: auto;
}

.item:not(:last-child) i{
  opacity: 0;
} 

.containerOpen 
.item:not(:last-child) i {
  opacity: 1;
}

.item:nth-last-child(1) {
  -webkit-transition-delay: 25ms;
          transition-delay: 25ms;
  background-size: contain;
}

.item:not(:last-child):nth-last-child(2) {
  -webkit-transition-delay: 50ms;
          transition-delay: 20ms;
  background-size: contain;
}

.item:not(:last-child):nth-last-child(3) {
  -webkit-transition-delay: 75ms;
          transition-delay: 40ms;
  background-size: contain;
}

.item:not(:last-child):nth-last-child(4) {
  -webkit-transition-delay: 100ms;
          transition-delay: 60ms;
  background-size: contain;
}

.item[tooltip]:before {
  bottom: 25%;
  border-radius: 2px;
  content: attr(tooltip);
  font-size: 12px;
  visibility: hidden;
  opacity: 0;
  padding: 5px 7px;
  margin-right: 12px;
  position: absolute;
  right: 100%;
  white-space: nowrap;
  @apply text-black bg-white border border-solid border-gray-400 drop-shadow-lg
} 

.item[tooltip]:hover:before,
.item[tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  transition: opacity .1s ease-in-out;
}

.item:nth-last-child(1)[tooltip]:hover:before,
.item:nth-last-child(1)[tooltip]:hover:after {
  transition: opacity .1s step-end;
}

.item.rotate:active, 
.item.rotate:focus, 
.item.rotate:hover 
{
  transform: rotate(360deg);
  box-shadow: 5px 5px 20px rgba(0,0,0,0.19), 3px 3px 6px rgba(0,0,0,0.23);
  transition: box-shadow .4s ease, transform .2s ease;
  outline: none;
}

.item.rotate:nth-last-child(1)[tooltip]:hover:before,
.item.rotate:nth-last-child(1)[tooltip]:hover:after 
{
  transform: rotate(-45deg);
  bottom: -60%;
  right: 60%;
}
